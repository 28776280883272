<template>
  <div>

   <!--  <PagoFlight />
    <Buscador />
    <CajaIntermediaFlight /> -->
    <DisponibilidadFlight />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  mdiCar,
  mdiBed,
  mdiWalletTravel,
  mdiSeatPassenger,
  mdiAirplaneTakeoff,
  mdiAirplaneLanding,
  mdiAirplane,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiBagCarryOn,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiChevronDown,
} from '@mdi/js'

/* import PagoFlight from '../utils/PagoFlight.vue' */
import DisponibilidadFlight from '../utils/DisponibilidadFlight.vue'

/* import Buscador from '../utils/Buscador.vue'
import CajaIntermediaFlight from '../utils/CajaIntermediaFlight.vue' */

export default {
  components: {

    /* Buscador,
    CajaIntermediaFlight,
    PagoFlight, */
    DisponibilidadFlight,
  },
  setup() {
    const tab = ref('')
    const countryList = [
      'USA',
      'Canada',
      'UK',
      'Denmark',
      'Germany',
      'Iceland',
      'Israel',
      'Mexico',
    ]
    const languageList = [
      'English',
      'German',
      'French',
      'Spanish',
      'Portuguese',
      'Russian',
      'Korean',
    ]
    const hora = ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00']
    const transmision = ['Automatico', 'manual']
    const adicionales = ['GPS', 'Asiento para ninnos', 'Neumaticos para nieve']
    const clase = ['Economica', 'Premium', 'Business', 'Primera']
    const aerolinea = ['Todas las aerolineas', '1', '2', '3']

    return {
      tab,
      hora,
      countryList,
      languageList,
      transmision,
      adicionales,
      radioGroup: 1,
      clase,
      aerolinea,
      mostrarida: true,
      mostrarmultiple: false,
      mostraridavuelta: false,
      ubicacionDesde: null,
      ubicacionHasta: null,
      fechaIda: null,
      fechaVuelta: null,
      claseVuelo: null,
      adultos: 0,
      ninnos: 0,
      bebes: 0,
      aerolin: null,
      value: null,
      ubicacion: null,
      correo: null,
      nombre: null,
      auto: null,
      telef: null,
      checkbox: null,
      age: 6,
      dialogm1: '',
      dialog: false,

      // icons
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiBagCarryOn,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiChevronDown,
      },
    }
  },
  methods: {
    cambiaraIda() {
      this.mostrarida = !this.mostrarida
    },
    cambiaraIdaVuelta() {
      this.mostraridavuelta = !this.mostraridavuelta
    },
    cambiaraMultiple() {
      this.mostrarmultiple = !this.mostrarmultiple
    },
    MostrarIda() {
      if (this.mostraridavuelta === true) {
        this.cambiaraIdaVuelta()
      }
      if (this.mostrarmultiple === true) {
        this.cambiaraMultiple()
      }
      if (this.mostrarida === false) {
        this.cambiaraIda()
      }
    },
    MostrarIdaVuelta() {
      if (this.mostrarida === true) {
        this.cambiaraIda()
      }
      if (this.mostrarmultiple === true) {
        this.cambiaraMultiple()
      }
      if (this.mostraridavuelta === false) {
        this.cambiaraIdaVuelta()
      }
    },
    MostrarMultiple() {
      if (this.mostrarida === true) {
        this.cambiaraIda()
      }
      if (this.mostraridavuelta === true) {
        this.cambiaraIdaVuelta()
      }
      if (this.mostrarmultiple === false) {
        this.cambiaraMultiple()
      }
    },
  },
}
</script>
