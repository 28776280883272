<template>
  <div>
    <v-row class="my-2">
      <v-col cols="12">
        <v-card>
          <v-container grid-list-xs>
            <div class="d-flex justify-start">
              <v-icon>{{ icons.mdiHeart }}</v-icon>
              <p class="mb-0 ml-1">0</p>
              <v-divider vertical class="mx-3"></v-divider>
              <p class="mb-0 ml-1">Preparar selección</p>
              <v-spacer></v-spacer>
              <v-divider vertical class="mx-3"></v-divider>
              <v-icon>{{ icons.mdiHeartBroken }}</v-icon>
              <p class="mb-0 ml-1">Borrar lista de vuelos preferidos</p>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="12">
        <template>
          <v-tabs>
            <v-tab>Mejor opción</v-tab>
            <v-tab>Más económicos</v-tab>
            <v-tab>Más rápido</v-tab>
            <v-spacer></v-spacer> -->
    <!-- Vista desktop tabs con filtros -->
    <!-- <div class="d-none d-md-block">
              <v-dialog scrollable width="40%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" class="ma-1" dark v-bind="attrs" v-on="on">
                    Filtros
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Filtros</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 70%;">
                    <v-row>
                      <v-col cols="12">
                        <v-select v-model="aerolin" :items="aerolinea" label="Aerolinea" value="Todas las aerolineas"
                          outlined dense hide-details></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <h4 class="pl-0 mb-0 pr-6" subtitle-2>
                          Escalas:
                        </h4>
                        <v-radio-group v-model="dialogm1" row>
                          <v-radio dense label="Todos los vuelos" value=""></v-radio>
                          <v-radio dense label="Vuelo directo" value=""></v-radio>
                          <v-radio dense label="Hasta 1 escala" value=""></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <h4 class="pl-0 mb-0 pr-6" subtitle-2>
                          Duración Escala:
                        </h4>
                        <v-slider max="50" min="-50" hide-details></v-slider>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h4 class="pl-0 mb-0 pr-6" subtitle-2>
                          Duración del viaje:
                        </h4>
                        <v-slider max="50" min="-50" hide-details></v-slider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <h4 class="pl-0 pr-6 mb-2" subtitle-2>
                          Frecuencias:
                        </h4>
                        <v-row>
                          <v-col cols="3" class="pb-0">
                            <v-checkbox v-model="checkbox1" true-value="1" class="mt-0" label="Lunes" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3" class="pb-0">
                            <v-checkbox v-model="checkbox2" active class="mt-0" label="Martes" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3" class="pb-0">
                            <v-checkbox v-model="checkbox3" active class="mt-0" label="Miércoles" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3" class="pb-0">
                            <v-checkbox v-model="checkbox4" active class="mt-0" label="Jueves" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3" class="pb-0">
                            <v-checkbox v-model="checkbox5" active class="mt-0" label="Viernes" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3" class="pb-0">
                            <v-checkbox v-model="checkbox6" active class="mt-0" label="Sábado" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="3" class="pb-0">
                            <v-checkbox v-model="checkbox7" active class="mt-0" label="Domingo" hide-details>
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" class="pt-8">
                        <v-select v-model="aerolin" :items="aerolinea" label="Aeropuertos" value="1" outlined dense
                          hide-details></v-select>
                      </v-col>
                      <v-col cols="6" class="pt-8">
                        <v-select v-model="aerolin" :items="aerolinea" label="Clase" value="1" outlined dense
                          hide-details></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <h4 class="pl-0 mb-0 pr-6" subtitle-2>
                          Equipaje:
                        </h4>
                        <v-radio-group v-model="dialogm1" row>
                          <v-radio dense label="Indiferente" value="Indiferente"></v-radio>
                          <v-radio dense label="Sin equipaje" value="Sin equipaje"></v-radio>
                          <v-radio dense label="Con equipaje" value="Con equipaje"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn width="100%" color="primary" text @click="dialog = false">
                      Aplicar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div> -->
    <!-- Vista movil filtros -->
    <!--  <div class="d-md-none">
              <v-dialog scrollable max-width="110%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on" width="100%" class="my-3">
                    Filtros
                  </v-btn>
                  <v-select outlined dense hide-details label="Ordenar"></v-select>
                </template>
                <v-card>
                  <v-card-title>Filtros</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: auto;">
                    <v-row>
                      <v-col cols="12">
                        <v-select v-model="aerolin" :items="aerolinea" label="Aerolinea" value="Todas las aerolineas"
                          outlined dense hide-details></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <h4 class="pl-0 mb-0 pr-6" subtitle-2>
                          Escalas:
                        </h4>
                        <v-radio-group v-model="dialogm1" row>
                          <v-radio dense label="Todos los vuelos" value=""></v-radio>
                          <v-radio dense label="Vuelo directo" value=""></v-radio>
                          <v-radio dense label="Hasta 1 escala" value=""></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="6">
                        <h4 class="pl-0 mb-0 pr-6" subtitle-2>
                          Duración Escala:
                        </h4>
                        <v-range-slider max="50" min="-50" hide-details></v-range-slider>
                      </v-col>
                      <v-col cols="12">
                        <h4 class="pl-0 mb-0 pr-6" subtitle-2>
                          Duración del viaje:
                        </h4>
                        <v-range-slider max="50" min="-50" hide-details></v-range-slider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <h4 class="pl-0 pr-6 mb-2" subtitle-2>
                          Frecuencias:
                        </h4>
                        <v-row>
                          <v-col cols="6" class="pb-0">
                            <v-checkbox v-model="checkbox1" true-value="1" class="mt-0" label="Lunes" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="6" class="pb-0">
                            <v-checkbox v-model="checkbox2" active class="mt-0" label="Martes" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="6" class="pb-0">
                            <v-checkbox v-model="checkbox3" active class="mt-0" label="Miércoles" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="6" class="pb-0">
                            <v-checkbox v-model="checkbox4" active class="mt-0" label="Jueves" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="6" class="pb-0">
                            <v-checkbox v-model="checkbox5" active class="mt-0" label="Viernes" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="6" class="pb-0">
                            <v-checkbox v-model="checkbox6" active class="mt-0" label="Sábado" hide-details>
                            </v-checkbox>
                          </v-col>
                          <v-col cols="6" class="pb-0">
                            <v-checkbox v-model="checkbox7" active class="mt-0" label="Domingo" hide-details>
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="pt-8">
                        <v-select v-model="aerolin" :items="aerolinea" label="Aeropuertos" value="1" outlined dense
                          hide-details>
                        </v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="pt-8">
                        <v-select v-model="aerolin" :items="aerolinea" label="Clase" value="1" outlined dense
                          hide-details>
                        </v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <h4 class="pl-0 mb-0 pr-6" subtitle-2>
                          Equipaje:
                        </h4>
                        <v-radio-group v-model="dialogm1" row>
                          <v-radio dense label="Indiferente" value="Indiferente"></v-radio>
                          <v-radio dense label="Sin equipaje" value="Sin equipaje"></v-radio>
                          <v-radio dense label="Con equipaje" value="Con equipaje"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn width="100%" color="primary" text @click="dialog = false">
                      Aplicar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div> -->
    <!-- </v-tabs>
        </template>
      </v-col>
    </v-row> -->

    <!-- Card desktop ida -->
    <div class="d-none d-md-block">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title id="titleDispVueloMine" style="background-color:#9155fd;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="white" v-bind="attrs" v-on="on">
                    {{ icons.mdiPlus }}
                  </v-icon>
                </template>
                <span>Aeromexico</span>
              </v-tooltip>
              <div class="d-flex justify-start align-baseline px-2">
                <p class="mb-0 pr-2">
                  Aerolínea:
                </p>
                <h5 class="mb-0" style="font-weight: bold;">
                  Aeromexico
                </h5>
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex justify-start align-baseline px-2">
                <p class="mb-0 pr-2">
                  Ticket Time Limit:
                </p>
                <h5 class="mb-0">
                  50 minutos
                </h5>
              </div>
            </v-card-title>
            <v-container id="fromToHeadMine" grid-list-xs>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-block justify-center">
                      <div class="d-flex justify-start align-center">
                        <h2>Nueva York</h2>
                        <v-icon class="px-2" color="primary">
                          {{ icons.mdiSwapHorizontal }}
                        </v-icon>
                        <h2>Madrid</h2>
                        <h2 class="px-2">.</h2>
                        <p class="mb-0 mt-2">
                          10 jun., 2022, vie.
                        </p>
                      </div>
                      <div class="d-flex justify-start align-center">
                        <p class="mb-0 pr-2">
                          Adolfo Suarez Madrid-Barajas Airport
                        </p>
                        <h4 class="mb-0 pr-2">
                          MAD
                        </h4>
                        <h4 class="mb-0 pr-2">
                          →
                        </h4>
                        <p class="mb-0 pr-2">
                          La Aurora International Airport
                        </p>
                        <h4 class="mb-0">
                          GUA
                        </h4>
                      </div>
                    </div>
                    <div>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon x-large v-bind="attrs" v-on="on">
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </template>
                        <span>Tooltip</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container id="expandDispMine" grid-list-xs>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-radio-group v-model="radioGroup" class="mt-0 pt-0" mandatory>
                    <v-expansion-panels accordion multiple>
                      <v-expansion-panel style="border: none;">
                        <div class="d-flex justify-space-between align-center px-4"
                          style="background-color: aliceblue;">

                          <v-row>
                            <v-col cols="1" class="d-flex justify-center align-center">
                              <v-radio value="radio-2" label="" class="mb-0 d-flex justify-center align-center">
                              </v-radio>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                <b>23:40</b> — 09:15
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                Duración del viaje 17 h 35 min.
                              </p>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                1 escala
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                4 h 15 min., Ciudad de México, desde 04:00
                              </p>
                            </v-col>
                            <v-col cols="1">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                AM-22
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                AM-672
                              </p>
                            </v-col>
                            <v-col cols="3">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                Boeing 787-900
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                Boeing 737-800 Winglets
                              </p>
                            </v-col>
                          </v-row>
                          <v-expansion-panel-header ripple style="background-color: aliceblue; width:40px"
                            class="d-flex justify-center align-center">
                          </v-expansion-panel-header>
                        </div>
                        <v-expansion-panel-content style="background-color: aliceblue;">
                          <v-container fluid class="ma-2" style="background-color: white;">
                            <v-row dense class="pt-0">
                              <v-col cols="12">
                                <v-timeline align-top dense class="pt-0">
                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>23:40</strong><br />
                                        <p>10 jun. 2022</p>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <p>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</p><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <p>Duración del viaje 17 h 35 min.</p><br />
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>

                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>04:00</strong><br />
                                        <p>11 jun. 2022</p>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" class="pt-4 pl-4 pb-0">
                                        <p class="mb-0 pr-2">
                                          Vuelo AM-22・Boeing 787-900
                                        </p>
                                        <p class="mb-0 pr-2">
                                          Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                        </p>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <a href="#">
                                          <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                        </a>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-container fluid class="ma-2">
                            <p class="mb-0">
                              <b>4 h 15 min.Escala: Ciudad de México, desde 04:00 Escala larga</b>
                            </p>
                          </v-container>
                          <v-container fluid class="ma-2" style="background-color: white;">
                            <v-row dense class="pt-0">
                              <v-col cols="12">
                                <v-timeline align-top dense class="pt-0">
                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>23:40</strong><br />
                                        <light>10 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <light>Duración del viaje 17 h 35 min.</light><br />
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>

                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>04:00</strong><br />
                                        <light>11 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" class="pt-4 pl-4 pb-0">
                                        <p class="mb-0 pr-2">
                                          Vuelo AM-22・Boeing 787-900
                                        </p>
                                        <p class="mb-0 pr-2">
                                          Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                        </p>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <a href="#">
                                          <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                        </a>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-container id="fromToHeadMine" grid-list-xs>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-block justify-center">
                      <div class="d-flex justify-start align-center">
                        <h2>Madrid</h2>
                        <v-icon class="px-2" color="primary">
                          {{ icons.mdiSwapHorizontal }}
                        </v-icon>
                        <h2>Nueva York</h2>
                        <h2 class="px-2">.</h2>
                        <p class="mb-0 mt-2">
                          10 jun., 2022, vie.
                        </p>
                      </div>
                      <div class="d-flex justify-start align-center">
                        <p class="mb-0 pr-2">
                          Adolfo Suarez Madrid-Barajas Airport
                        </p>
                        <h4 class="mb-0 pr-2">
                          MAD
                        </h4>
                        <h4 class="mb-0 pr-2">
                          →
                        </h4>
                        <p class="mb-0 pr-2">
                          La Aurora International Airport
                        </p>
                        <h4 class="mb-0">
                          GUA
                        </h4>
                      </div>
                    </div>
                    <div>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon x-large v-bind="attrs" v-on="on">
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </template>
                        <span>Tooltip</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container id="expandDispMine" grid-list-xs>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-radio-group v-model="radioGroup" class="mt-0 pt-0" mandatory>
                    <v-expansion-panels accordion multiple>
                      <v-expansion-panel style="border: none;">
                        <div class="d-flex justify-space-between align-center px-4"
                          style="background-color: aliceblue;">

                          <v-row>
                            <v-col cols="1" class="d-flex justify-center align-center">
                              <v-radio value="radio-2" label="" class="mb-0 d-flex justify-center align-center">
                              </v-radio>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                <b>23:40</b> — 09:15
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                Duración del viaje 17 h 35 min.
                              </p>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                1 escala
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                4 h 15 min., Ciudad de México, desde 04:00
                              </p>
                            </v-col>
                            <v-col cols="1">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                AM-22
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                AM-672
                              </p>
                            </v-col>
                            <v-col cols="3">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                Boeing 787-900
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                Boeing 737-800 Winglets
                              </p>
                            </v-col>
                          </v-row>
                          <v-expansion-panel-header ripple style="background-color: aliceblue; width:40px"
                            class="d-flex justify-center align-center">
                          </v-expansion-panel-header>
                        </div>
                        <v-expansion-panel-content style="background-color: aliceblue;">
                          <v-container fluid class="ma-2" style="background-color: white;">
                            <v-row dense class="pt-0">
                              <v-col cols="12">
                                <v-timeline align-top dense class="pt-0">
                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>23:40</strong><br />
                                        <light>10 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <light>Duración del viaje 17 h 35 min.</light><br />
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>

                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>04:00</strong><br />
                                        <light>11 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" class="pt-4 pl-4 pb-0">
                                        <p class="mb-0 pr-2">
                                          Vuelo AM-22・Boeing 787-900
                                        </p>
                                        <p class="mb-0 pr-2">
                                          Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                        </p>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <a href="#">
                                          <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                        </a>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-container fluid class="ma-2">
                            <p class="mb-0">
                              <b>4 h 15 min.Escala: Ciudad de México, desde 04:00 Escala larga</b>
                            </p>
                          </v-container>
                          <v-container fluid class="ma-2" style="background-color: white;">
                            <v-row dense class="pt-0">
                              <v-col cols="12">
                                <v-timeline align-top dense class="pt-0">
                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>23:40</strong><br />
                                        <light>10 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <light>Duración del viaje 17 h 35 min.</light><br />
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>

                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>04:00</strong><br />
                                        <light>11 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" class="pt-4 pl-4 pb-0">
                                        <p class="mb-0 pr-2">
                                          Vuelo AM-22・Boeing 787-900
                                        </p>
                                        <p class="mb-0 pr-2">
                                          Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                        </p>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <a href="#">
                                          <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                        </a>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>

            <v-divider class="px-0 py-3"></v-divider>
            <v-container id="" grid-list-xs>
              <v-row>
                <v-col cols="12" md="8" class="pt-0">
                  <v-card elevation="0" style="border: 1px solid #e3e1e4;">
                    <v-container grid-list-xs>
                      <!-- Card tarifa desktop -->
                      <v-row>
                        <v-col cols="8">
                          <div class="d-block">
                            <p class="mb-0 d-flex justify-start text-h6">
                              <b>Ida</b>
                            </p>
                            <p class="mb-0 d-flex justify-start">
                              <b>Nombre de la Tarifa</b>
                            </p>
                            <p class="mb-0 d-flex justify-start">
                              Clase: <b class="pl-2">Nombre de la Clase</b>
                            </p>
                          </div>
                          <v-row class="pt-4">
                            <v-col cols="5">
                              <div class="d-flex justify-start align-center">
                                <v-icon style="color: #53af24;">
                                  {{ icons.mdiBagSuitcase }}
                                </v-icon>
                                <p style="color: #53af24;" class="p14Mine mb-0 d-flex justify-center align-baseline">
                                  Equipaje: 1 pieza
                                </p>
                              </div>
                            </v-col>
                            <v-col cols="7">
                              <div class="d-flex justify-start align-center">
                                <v-icon style="color: #53af24;">
                                  {{ icons.mdiBagPersonal }}
                                </v-icon>
                                <p style="color: #53af24;" class="p14Mine mb-0 d-flex justify-center align-baseline">
                                  Equipaje de mano: incluido
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                          <v-divider class="my-4"></v-divider>
                          <div class="d-block">
                            <p class="mb-0 d-flex justify-start text-h6">
                              <b>Vuelta</b>
                            </p>
                            <p class="mb-0 d-flex justify-start">
                              <b>Nombre de la Tarifa</b>
                            </p>
                            <p class="mb-0 d-flex justify-start">
                              Clase: <b class="pl-2">Nombre de la Clase</b>
                            </p>
                          </div>
                          <v-row class="pt-4">
                            <v-col cols="5">
                              <div class="d-flex justify-start align-center">
                                <v-icon style="color: #53af24;">
                                  {{ icons.mdiBagSuitcase }}
                                </v-icon>
                                <p style="color: #53af24;" class="p14Mine mb-0 d-flex justify-center align-baseline">
                                  Equipaje: 1 pieza
                                </p>
                              </div>
                            </v-col>
                            <v-col cols="7">
                              <div class="d-flex justify-start align-center">
                                <v-icon style="color: #53af24;">
                                  {{ icons.mdiBagPersonal }}
                                </v-icon>
                                <p style="color: #53af24;" class="p14Mine mb-0 d-flex justify-center align-baseline">
                                  Equipaje de mano: incluido
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="4">
                          <div class="d-block">
                            <p class="mb-0 pr-12 d-flex justify-end align-center">
                              817,76 $
                            </p>
                            <div class="pr-6 d-flex justify-end">
                              <p class="mb-0 d-flex justify-center align-center">
                                <b>776,87 $</b>
                              </p>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="primary" v-bind="attrs" v-on="on">{{ icons.mdiInformation }}</v-icon>
                                </template>
                                <span>
                                  <div class="d-block">
                                    <div class="d-flex justify-start align-center">
                                      <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                    </div>
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="mb-0 pr-6">Tarifa</p>
                                      <p class="mb-0">776,87 $</p>
                                    </div>
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="mb-0 pr-6" subtitle-2>Impuestos y tasas</p>
                                      <p class="mb-0" subtitle-2>776,87 $</p>
                                    </div>
                                    <v-divider color="white" class="mt-2"></v-divider>
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="my-2"><b>Total</b></p>
                                      <p class="my-2" subtitle-2>776,87 $</p>
                                    </div>
                                    <div style="background-color: #ffffff6b" class="mb-4 pa-2">
                                      <div class="d-flex justify-space-between align-center">
                                        <p class="mb-0">Tu comisión</p>
                                        <p class="mb-0" subtitle-2>776,87 $</p>
                                      </div>
                                      <div class="d-flex justify-space-between align-center">
                                        <p class="mb-0"><b>PVP</b></p>
                                        <p class="mb-0" subtitle-2>776,87 $</p>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </v-tooltip>
                            </div>
                            <p class="mb-0 pr-6 p12Mine d-flex justify-end" style="color:#ce2121">
                              Quedan pocos asientos
                              a este precio</p>
                            <!-- Para V tooltip -->
                            <p class="mb-0 pr-6 p12Mine d-flex justify-end" style="color:#ce2121">
                              Diversas clases de servicio
                              <v-tooltip top min-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="error" small dark v-bind="attrs" v-on="on" class="">
                                    {{ icons.mdiInformation }}
                                  </v-icon>
                                </template>
                                <span>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="5">
                                        <v-row class="pt-1">
                                          <p caption class="mb-0 font-wight-bold text-h6">
                                            Ida
                                          </p>
                                        </v-row>
                                        <v-row class="pt-1 text-body-2">
                                          <p class="mb-0 pr-2">
                                            Madrid
                                          </p>
                                          <p class="mb-0 pr-2">
                                            MAD
                                          </p>
                                          <p class="mb-0 pr-2">
                                            →
                                          </p>
                                          <p class="mb-0 pr-2">
                                            Guatemala
                                          </p>
                                          <p class="mb-0">
                                            GUA
                                          </p>
                                        </v-row>
                                        <v-row class="py-2" v-for="(item, i) in 3" :key="i">
                                          <v-col cols="12" class="" style="border:1px solid white; border-radius:5px">
                                            <v-row class="pa-2">
                                              <p class="mb-0 pr-2">
                                                Madrid
                                              </p>
                                              <p class="mb-0 pr-2">
                                                MAD
                                              </p>
                                              <p class="mb-0 pr-2">
                                                →
                                              </p>
                                              <p class="mb-0 pr-2">
                                                Guatemala
                                              </p>
                                              <p class="mb-0">
                                                GUA
                                              </p>
                                            </v-row>
                                            <v-row class="pa-2 pt-0">
                                              <p class="mb-0 pr-2 text-subtitle-2 font-weight-bold">
                                                Premiun Business
                                              </p>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                      <v-col cols="2" class="px-0 d-flex justify-center">
                                        <v-divider vertical color="white" class="d-flex justify-center"></v-divider>
                                      </v-col>
                                      <v-col cols="5">
                                        <v-row class="pt-1">
                                          <p caption class="mb-0 font-wight-bold text-h6">
                                            Vuelta
                                          </p>
                                        </v-row>
                                        <v-row class="pt-1 text-body-2">
                                          <p class="mb-0 pr-2">
                                            Madrid
                                          </p>
                                          <p class="mb-0 pr-2">
                                            MAD
                                          </p>
                                          <p class="mb-0 pr-2">
                                            →
                                          </p>
                                          <p class="mb-0 pr-2">
                                            Guatemala
                                          </p>
                                          <p class="mb-0">
                                            GUA
                                          </p>
                                        </v-row>
                                        <v-row class="py-2" v-for="(item, i) in 3" :key="i">
                                          <v-col cols="12" class="" style="border:1px solid white; border-radius:5px">
                                            <v-row class="pa-2">
                                              <p class="mb-0 pr-2">
                                                Madrid
                                              </p>
                                              <p class="mb-0 pr-2">
                                                MAD
                                              </p>
                                              <p class="mb-0 pr-2">
                                                →
                                              </p>
                                              <p class="mb-0 pr-2">
                                                Guatemala
                                              </p>
                                              <p class="mb-0">
                                                GUA
                                              </p>
                                            </v-row>
                                            <v-row class="pa-2 pt-0">
                                              <p class="mb-0 pr-2 text-subtitle-2 font-weight-bold">
                                                Premiun Business
                                              </p>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </span>
                              </v-tooltip>
                            </p>
                            <!-- Finp para V tooltip -->
                            <a href="#">
                              <p style="position: absolute; bottom:15px; right: 35px;"
                                class="p14Mine mb-0 d-flex justify-center align-center">
                                Reglas de la tarifa
                              </p>
                            </a>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4" class="pt-0">
                  <v-btn color="primary" width="100%" class="mb-2">
                    Enviar
                  </v-btn>
                  <div class="d-flex justify-center align-center">
                    <v-container grid-list-xs>
                      <v-row>
                        <v-col cols="2" class="pr-0">
                          <a href="#">
                            <v-icon large>{{ icons.mdiHeartOutline, }}</v-icon>
                          </a>
                        </v-col>
                        <v-col cols="10" class="pr-0">
                          <!-- Modal!! -->
                          <v-dialog v-model="dialog" width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="#e3e1e4" width="100%" v-bind="attrs" v-on="on">
                                Otras tarifas con equipaje<v-icon>{{ icons.mdiChevronDown }} </v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-icon style="position:fixed; top:2rem; right:2rem" large class="mr-5 mt-4" @click="dialog = false">{{ icons.mdiClose
                              }}
                              </v-icon>
                              <v-container>
                                <v-row>
                                  <v-col cols="6">
                                    <v-card-text class="mt-4 grey lighten-2 pb-0 text-h6 px-0">
                                      Ida: Madrid (MAD)
                                      <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                                      Havana (HAV) 10 jun, 2022, vie.
                                    </v-card-text>
                                    <v-card-text class="mt-0 grey lighten-2 pb-0 text-h6 px-0">
                                      10 jun, 2022, vie.
                                    </v-card-text>
                                    <!-- Card tarifa desktop -->
                                    <v-card style="border:1px solid #e3e1e4" class="my-2" v-for="(item, i) in 5"
                                      :key="i">
                                      <v-container grid-list-xs>
                                        <v-row>
                                          <v-col cols="6">
                                            <div class="d-block">
                                              <p class="mb-0 d-flex justify-start">
                                                <v-radio label="" dense value="value"></v-radio>
                                                <b class="pl-2">Nombre de la Clase</b>
                                              </p>
                                              <p class="mb-0 d-flex justify-start">
                                                <b>Tarifa: Nombre de la Tarifa</b>
                                              </p>
                                            </div>
                                            <v-row class="pt-4">
                                              <v-col cols="12" class="pb-0">
                                                <div class="d-flex justify-start align-center">
                                                  <v-icon style="color: #53af24;">
                                                    {{ icons.mdiBagSuitcase }}
                                                  </v-icon>
                                                  <p style="color: #53af24;"
                                                    class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                    Equipaje: 1 pieza
                                                  </p>
                                                </div>
                                              </v-col>
                                              <v-col cols="12">
                                                <div class="d-flex justify-start align-center">
                                                  <v-icon style="color: #53af24;">
                                                    {{ icons.mdiBagPersonal }}
                                                  </v-icon>
                                                  <p style="color: #53af24;"
                                                    class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                    Equipaje de mano: incluido
                                                  </p>
                                                </div>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                          <v-col cols="6">
                                            <div class="d-block">
                                              <p class="mb-0 pr-12 d-flex justify-end align-center">
                                                817,76 $
                                              </p>
                                              <div class="pr-6 d-flex justify-end">
                                                <p class="mb-0 d-flex justify-center align-center">
                                                  <b>776,87 $</b>
                                                </p>
                                                <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="primary" v-bind="attrs" v-on="on">{{
                                                        icons.mdiInformation
                                                    }}</v-icon>
                                                  </template>
                                                  <span>
                                                    <div class="d-block">
                                                      <div class="d-flex justify-start align-center">
                                                        <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                                      </div>
                                                      <div class="d-flex justify-space-between align-center">
                                                        <p class="mb-0 pr-6">Tarifa</p>
                                                        <p class="mb-0">776,87 $</p>
                                                      </div>
                                                      <div class="d-flex justify-space-between align-center">
                                                        <p class="mb-0 pr-6" subtitle-2>Impuestos y tasas</p>
                                                        <p class="mb-0" subtitle-2>776,87 $</p>
                                                      </div>
                                                      <v-divider color="white" class="mt-2"></v-divider>
                                                      <div class="d-flex justify-space-between align-center">
                                                        <p class="my-2"><b>Total</b></p>
                                                        <p class="my-2" subtitle-2>776,87 $</p>
                                                      </div>
                                                      <div style="background-color: #ffffff6b" class="mb-4 pa-2">
                                                        <div class="d-flex justify-space-between align-center">
                                                          <p class="mb-0">Tu comisión</p>
                                                          <p class="mb-0" subtitle-2>776,87 $</p>
                                                        </div>
                                                        <div class="d-flex justify-space-between align-center">
                                                          <p class="mb-0"><b>PVP</b></p>
                                                          <p class="mb-0" subtitle-2>776,87 $</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </span>
                                                </v-tooltip>
                                              </div>
                                              <p class="mb-0 pr-6 p12Mine d-flex justify-end" style="color:#ce2121">
                                                Cupos disponibles (#)</p>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="6">
                                    <v-card-text class="mt-4 grey lighten-2 pb-0 text-h6 px-0">
                                      Vuelta: Madrid (MAD)
                                      <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                                      Havana (HAV)
                                    </v-card-text>
                                    <v-card-text class="mt-0 grey lighten-2 pb-0 text-h6 px-0">
                                      10 jun, 2022, vie.
                                    </v-card-text>
                                    <v-card style="border:1px solid #e3e1e4" class="my-2" v-for="(item, i) in 5"
                                      :key="i">
                                      <v-container grid-list-xs>
                                        <v-row>
                                          <v-col cols="6">
                                            <div class="d-block">
                                              <p class="mb-0 d-flex justify-start">
                                                <v-radio label="" dense value="value"></v-radio>
                                                <b class="pl-2">Nombre de la Clase</b>
                                              </p>
                                              <p class="mb-0 d-flex justify-start">
                                                <b>Tarifa: Nombre de la Tarifa</b>
                                              </p>
                                            </div>
                                            <v-row class="pt-4">
                                              <v-col cols="12" class="pb-0">
                                                <div class="d-flex justify-start align-center">
                                                  <v-icon style="color: #53af24;">
                                                    {{ icons.mdiBagSuitcase }}
                                                  </v-icon>
                                                  <p style="color: #53af24;"
                                                    class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                    Equipaje: 1 pieza
                                                  </p>
                                                </div>
                                              </v-col>
                                              <v-col cols="12">
                                                <div class="d-flex justify-start align-center">
                                                  <v-icon style="color: #53af24;">
                                                    {{ icons.mdiBagPersonal }}
                                                  </v-icon>
                                                  <p style="color: #53af24;"
                                                    class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                    Equipaje de mano: incluido
                                                  </p>
                                                </div>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                          <v-col cols="6">
                                            <div class="d-block">
                                              <p class="mb-0 pr-12 d-flex justify-end align-center">
                                                817,76 $
                                              </p>
                                              <div class="pr-6 d-flex justify-end">
                                                <p class="mb-0 d-flex justify-center align-center">
                                                  <b>776,87 $</b>
                                                </p>
                                                <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="primary" v-bind="attrs" v-on="on">{{
                                                        icons.mdiInformation
                                                    }}</v-icon>
                                                  </template>
                                                  <span>
                                                    <div class="d-block">
                                                      <div class="d-flex justify-start align-center">
                                                        <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                                      </div>
                                                      <div class="d-flex justify-space-between align-center">
                                                        <p class="mb-0 pr-6">Tarifa</p>
                                                        <p class="mb-0">776,87 $</p>
                                                      </div>
                                                      <div class="d-flex justify-space-between align-center">
                                                        <p class="mb-0 pr-6" subtitle-2>Impuestos y tasas</p>
                                                        <p class="mb-0" subtitle-2>776,87 $</p>
                                                      </div>
                                                      <v-divider color="white" class="mt-2"></v-divider>
                                                      <div class="d-flex justify-space-between align-center">
                                                        <p class="my-2"><b>Total</b></p>
                                                        <p class="my-2" subtitle-2>776,87 $</p>
                                                      </div>
                                                      <div style="background-color: #ffffff6b" class="mb-4 pa-2">
                                                        <div class="d-flex justify-space-between align-center">
                                                          <p class="mb-0">Tu comisión</p>
                                                          <p class="mb-0" subtitle-2>776,87 $</p>
                                                        </div>
                                                        <div class="d-flex justify-space-between align-center">
                                                          <p class="mb-0"><b>PVP</b></p>
                                                          <p class="mb-0" subtitle-2>776,87 $</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </span>
                                                </v-tooltip>
                                              </div>
                                              <p class="mb-0 pr-6 p12Mine d-flex justify-end" style="color:#ce2121">
                                                Cupos disponibles (#)</p>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-col>
              </v-row>
              <v-row>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Card movil ida -->
    <div class="d-md-none">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title id="titleDispVueloMine" style="background-color:#9155fd;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="white" v-bind="attrs" v-on="on">
                    {{ icons.mdiPlus }}
                  </v-icon>
                </template>
                <span>Aeromexico</span>
              </v-tooltip>
              <div class="d-flex justify-start align-baseline px-2">
                <p class="mb-0 pr-2">
                  Aerolínea:
                </p>
                <h5 class="mb-0" style="font-weight: bold;">
                  Aeromexico
                </h5>
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex justify-start align-baseline px-2">
                <p class="mb-0 pr-2">
                  Ticket Time Limit:
                </p>
                <h5 class="mb-0">
                  50 minutos
                </h5>
              </div>
            </v-card-title>
            <v-container id="fromToHeadMine" grid-list-xs>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-block justify-center">
                      <div class="d-flex justify-start align-center">
                        <h2>Nueva York</h2>
                        <v-icon class="px-2" color="primary">
                          {{ icons.mdiSwapHorizontal }}
                        </v-icon>
                        <h2>Madrid</h2>
                        <h2 class="px-2">.</h2>
                        <p class="mb-0 mt-2">
                          10 jun., 2022, vie.
                        </p>
                      </div>
                      <div class="d-flex justify-start align-center">
                        <p class="mb-0 pr-2">
                          Adolfo Suarez Madrid-Barajas Airport
                        </p>
                        <h4 class="mb-0 pr-2">
                          MAD
                        </h4>
                        <h4 class="mb-0 pr-2">
                          →
                        </h4>
                        <p class="mb-0 pr-2">
                          La Aurora International Airport
                        </p>
                        <h4 class="mb-0">
                          GUA
                        </h4>
                      </div>
                    </div>
                    <div>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon x-large v-bind="attrs" v-on="on">
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </template>
                        <span>Tooltip</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container id="expandDispMine" grid-list-xs>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-radio-group v-model="radioGroup" class="mt-0 pt-0" mandatory>
                    <v-expansion-panels accordion multiple>
                      <v-expansion-panel style="border: none;">
                        <div class="d-flex justify-space-between align-center px-4"
                          style="background-color: aliceblue;">

                          <v-row>
                            <v-col cols="1" class="d-flex justify-center align-center">
                              <v-radio value="radio-2" label="" class="mb-0 d-flex justify-center align-center">
                              </v-radio>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                <b>23:40</b> — 09:15
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                Duración del viaje 17 h 35 min.
                              </p>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                1 escala
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                4 h 15 min., Ciudad de México, desde 04:00
                              </p>
                            </v-col>
                            <v-col cols="1">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                AM-22
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                AM-672
                              </p>
                            </v-col>
                            <v-col cols="3">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                Boeing 787-900
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                Boeing 737-800 Winglets
                              </p>
                            </v-col>
                          </v-row>
                          <v-expansion-panel-header ripple style="background-color: aliceblue; width:40px"
                            class="d-flex justify-center align-center">
                          </v-expansion-panel-header>
                        </div>
                        <v-expansion-panel-content style="background-color: aliceblue;">
                          <v-container fluid class="ma-2" style="background-color: white;">
                            <v-row dense class="pt-0">
                              <v-col cols="12">
                                <v-timeline align-top dense class="pt-0">
                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>23:40</strong><br />
                                        <light>10 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <light>Duración del viaje 17 h 35 min.</light><br />
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>

                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>04:00</strong><br />
                                        <light>11 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" class="pt-4 pl-4 pb-0">
                                        <p class="mb-0 pr-2">
                                          Vuelo AM-22・Boeing 787-900
                                        </p>
                                        <p class="mb-0 pr-2">
                                          Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                        </p>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <a href="#">
                                          <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                        </a>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-container fluid class="ma-2">
                            <p class="mb-0">
                              <b>4 h 15 min.Escala: Ciudad de México, desde 04:00 Escala larga</b>
                            </p>
                          </v-container>
                          <v-container fluid class="ma-2" style="background-color: white;">
                            <v-row dense class="pt-0">
                              <v-col cols="12">
                                <v-timeline align-top dense class="pt-0">
                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>23:40</strong><br />
                                        <light>10 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <light>Duración del viaje 17 h 35 min.</light><br />
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>

                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>04:00</strong><br />
                                        <light>11 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" class="pt-4 pl-4 pb-0">
                                        <p class="mb-0 pr-2">
                                          Vuelo AM-22・Boeing 787-900
                                        </p>
                                        <p class="mb-0 pr-2">
                                          Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                        </p>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <a href="#">
                                          <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                        </a>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-container id="fromToHeadMine" grid-list-xs>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-block justify-center">
                      <div class="d-flex justify-start align-center">
                        <h2>Madrid</h2>
                        <v-icon class="px-2" color="primary">
                          {{ icons.mdiSwapHorizontal }}
                        </v-icon>
                        <h2>Nueva York</h2>
                        <h2 class="px-2">.</h2>
                        <p class="mb-0 mt-2">
                          10 jun., 2022, vie.
                        </p>
                      </div>
                      <div class="d-flex justify-start align-center">
                        <p class="mb-0 pr-2">
                          Adolfo Suarez Madrid-Barajas Airport
                        </p>
                        <h4 class="mb-0 pr-2">
                          MAD
                        </h4>
                        <h4 class="mb-0 pr-2">
                          →
                        </h4>
                        <p class="mb-0 pr-2">
                          La Aurora International Airport
                        </p>
                        <h4 class="mb-0">
                          GUA
                        </h4>
                      </div>
                    </div>
                    <div>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon x-large v-bind="attrs" v-on="on">
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </template>
                        <span>Tooltip</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container id="expandDispMine" grid-list-xs>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-radio-group v-model="radioGroup" class="mt-0 pt-0" mandatory>
                    <v-expansion-panels accordion multiple>
                      <v-expansion-panel style="border: none;">
                        <div class="d-flex justify-space-between align-center px-4"
                          style="background-color: aliceblue;">

                          <v-row>
                            <v-col cols="1" class="d-flex justify-center align-center">
                              <v-radio value="radio-2" label="" class="mb-0 d-flex justify-center align-center">
                              </v-radio>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                <b>23:40</b> — 09:15
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                Duración del viaje 17 h 35 min.
                              </p>
                            </v-col>
                            <v-col cols="2">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                1 escala
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                4 h 15 min., Ciudad de México, desde 04:00
                              </p>
                            </v-col>
                            <v-col cols="1">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                AM-22
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                AM-672
                              </p>
                            </v-col>
                            <v-col cols="3">
                              <p class="mb-0 pr-2 pt-4 pb-0">
                                Boeing 787-900
                              </p>
                              <p class="mb-0 pr-2 pb-4 pt-0">
                                Boeing 737-800 Winglets
                              </p>
                            </v-col>
                          </v-row>
                          <v-expansion-panel-header ripple style="background-color: aliceblue; width:40px"
                            class="d-flex justify-center align-center">
                          </v-expansion-panel-header>
                        </div>
                        <v-expansion-panel-content style="background-color: aliceblue;">
                          <v-container fluid class="ma-2" style="background-color: white;">
                            <v-row dense class="pt-0">
                              <v-col cols="12">
                                <v-timeline align-top dense class="pt-0">
                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>23:40</strong><br />
                                        <light>10 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <light>Duración del viaje 17 h 35 min.</light><br />
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>

                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>04:00</strong><br />
                                        <light>11 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" class="pt-4 pl-4 pb-0">
                                        <p class="mb-0 pr-2">
                                          Vuelo AM-22・Boeing 787-900
                                        </p>
                                        <p class="mb-0 pr-2">
                                          Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                        </p>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <a href="#">
                                          <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                        </a>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-container fluid class="ma-2">
                            <p class="mb-0">
                              <b>4 h 15 min.Escala: Ciudad de México, desde 04:00 Escala larga</b>
                            </p>
                          </v-container>
                          <v-container fluid class="ma-2" style="background-color: white;">
                            <v-row dense class="pt-0">
                              <v-col cols="12">
                                <v-timeline align-top dense class="pt-0">
                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>23:40</strong><br />
                                        <light>10 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <light>Duración del viaje 17 h 35 min.</light><br />
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>

                                  <v-timeline-item color="primary" small>
                                    <v-row class="pt-1">
                                      <v-col cols="2">
                                        <strong>04:00</strong><br />
                                        <light>11 jun. 2022</light>
                                      </v-col>
                                      <v-col cols="10">
                                        <strong class="pr-2">MAD</strong><br>
                                        <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                        <div class="text-caption">
                                          Madrid
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" class="pt-4 pl-4 pb-0">
                                        <p class="mb-0 pr-2">
                                          Vuelo AM-22・Boeing 787-900
                                        </p>
                                        <p class="mb-0 pr-2">
                                          Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                        </p>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <a href="#">
                                          <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                        </a>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>

            <v-divider class="px-0 py-3"></v-divider>
            <v-container id="" grid-list-xs>
              <v-row>
                <v-col cols="12" md="8" class="pt-0">
                  <v-card elevation="0" style="border: 1px solid #e3e1e4;">
                    <v-container grid-list-xs>
                      <!-- Card tarifa desktop -->
                      <v-row>
                        <v-col cols="12">
                          <div class="d-block">
                            <p class="mb-0 d-flex justify-start text-h6">
                              <b>Ida</b>
                            </p>
                            <p class="mb-0 d-flex justify-start">
                              <b>Nombre de la Tarifa</b>
                            </p>
                            <p class="mb-0 d-flex justify-start">
                              Clase: <b class="pl-2">Nombre de la Clase</b>
                            </p>
                          </div>
                          <v-row class="pt-4">
                            <v-col cols="12">
                              <div class="d-flex justify-start align-center">
                                <v-icon style="color: #53af24;">
                                  {{ icons.mdiBagSuitcase }}
                                </v-icon>
                                <p style="color: #53af24;" class="p14Mine mb-0 d-flex justify-center align-baseline">
                                  Equipaje: 1 pieza
                                </p>
                              </div>
                            </v-col>
                            <v-col cols="12">
                              <div class="d-flex justify-start align-center">
                                <v-icon style="color: #53af24;">
                                  {{ icons.mdiBagPersonal }}
                                </v-icon>
                                <p style="color: #53af24;" class="p14Mine mb-0 d-flex justify-center align-baseline">
                                  Equipaje de mano: incluido
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                          <v-divider class="my-4"></v-divider>
                          <div class="d-block">
                            <p class="mb-0 d-flex justify-start text-h6">
                              <b>Vuelta</b>
                            </p>
                            <p class="mb-0 d-flex justify-start">
                              <b>Nombre de la Tarifa</b>
                            </p>
                            <p class="mb-0 d-flex justify-start">
                              Clase: <b class="pl-2">Nombre de la Clase</b>
                            </p>
                          </div>
                          <v-row class="pt-4">
                            <v-col cols="12">
                              <div class="d-flex justify-start align-center">
                                <v-icon style="color: #53af24;">
                                  {{ icons.mdiBagSuitcase }}
                                </v-icon>
                                <p style="color: #53af24;" class="p14Mine mb-0 d-flex justify-center align-baseline">
                                  Equipaje: 1 pieza
                                </p>
                              </div>
                            </v-col>
                            <v-col cols="12">
                              <div class="d-flex justify-start align-center">
                                <v-icon style="color: #53af24;">
                                  {{ icons.mdiBagPersonal }}
                                </v-icon>
                                <p style="color: #53af24;" class="p14Mine mb-0 d-flex justify-center align-baseline">
                                  Equipaje de mano: incluido
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <div class="d-block">
                            <p class="mb-0 d-flex justify-end align-center">
                              817,76 $
                            </p>
                            <div class=" d-flex justify-end">
                              <p class="mb-0 d-flex justify-center align-center">
                                <b>776,87 $</b>
                              </p>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="primary" v-bind="attrs" v-on="on">{{ icons.mdiInformation }}</v-icon>
                                </template>
                                <span>
                                  <div class="d-block">
                                    <div class="d-flex justify-start align-center">
                                      <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                    </div>
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="mb-0 pr-6">Tarifa</p>
                                      <p class="mb-0">776,87 $</p>
                                    </div>
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="mb-0 pr-6" subtitle-2>Impuestos y tasas</p>
                                      <p class="mb-0" subtitle-2>776,87 $</p>
                                    </div>
                                    <v-divider color="white" class="mt-2"></v-divider>
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="my-2"><b>Total</b></p>
                                      <p class="my-2" subtitle-2>776,87 $</p>
                                    </div>
                                    <div style="background-color: #ffffff6b" class="mb-4 pa-2">
                                      <div class="d-flex justify-space-between align-center">
                                        <p class="mb-0">Tu comisión</p>
                                        <p class="mb-0" subtitle-2>776,87 $</p>
                                      </div>
                                      <div class="d-flex justify-space-between align-center">
                                        <p class="mb-0"><b>PVP</b></p>
                                        <p class="mb-0" subtitle-2>776,87 $</p>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </v-tooltip>
                            </div>
                            <p class="mb-0 p12Mine d-flex justify-end" style="color:#ce2121">
                              Quedan pocos asientos
                              a este precio</p>
                            <!-- Para V tooltip -->
                            <p class="mb-0 p12Mine d-flex justify-end" style="color:#ce2121">
                              Diversas clases de servicio
                              <v-tooltip top min-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="error" small dark v-bind="attrs" v-on="on" class="">
                                    {{ icons.mdiInformation }}
                                  </v-icon>
                                </template>
                                <span>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="5">
                                        <v-row class="pt-1">
                                          <p caption class="mb-0 font-wight-bold text-h6">
                                            Ida
                                          </p>
                                        </v-row>
                                        <v-row class="pt-1 text-body-2">
                                          <p class="mb-0 pr-2">
                                            Madrid
                                          </p>
                                          <p class="mb-0 pr-2">
                                            MAD
                                          </p>
                                          <p class="mb-0 pr-2">
                                            →
                                          </p>
                                          <p class="mb-0 pr-2">
                                            Guatemala
                                          </p>
                                          <p class="mb-0">
                                            GUA
                                          </p>
                                        </v-row>
                                        <v-row class="py-2" v-for="(item, i) in 3" :key="i">
                                          <v-col cols="12" class="" style="border:1px solid white; border-radius:5px">
                                            <v-row class="pa-2">
                                              <p class="mb-0 pr-2">
                                                Madrid
                                              </p>
                                              <p class="mb-0 pr-2">
                                                MAD
                                              </p>
                                              <p class="mb-0 pr-2">
                                                →
                                              </p>
                                              <p class="mb-0 pr-2">
                                                Guatemala
                                              </p>
                                              <p class="mb-0">
                                                GUA
                                              </p>
                                            </v-row>
                                            <v-row class="pa-2 pt-0">
                                              <p class="mb-0 pr-2 text-subtitle-2 font-weight-bold">
                                                Premiun Business
                                              </p>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                      <v-col cols="2" class="px-0 d-flex justify-center">
                                        <v-divider vertical color="white" class="d-flex justify-center"></v-divider>
                                      </v-col>
                                      <v-col cols="5">
                                        <v-row class="pt-1">
                                          <p caption class="mb-0 font-wight-bold text-h6">
                                            Vuelta
                                          </p>
                                        </v-row>
                                        <v-row class="pt-1 text-body-2">
                                          <p class="mb-0 pr-2">
                                            Madrid
                                          </p>
                                          <p class="mb-0 pr-2">
                                            MAD
                                          </p>
                                          <p class="mb-0 pr-2">
                                            →
                                          </p>
                                          <p class="mb-0 pr-2">
                                            Guatemala
                                          </p>
                                          <p class="mb-0">
                                            GUA
                                          </p>
                                        </v-row>
                                        <v-row class="py-2" v-for="(item, i) in 3" :key="i">
                                          <v-col cols="12" class="" style="border:1px solid white; border-radius:5px">
                                            <v-row class="pa-2">
                                              <p class="mb-0 pr-2">
                                                Madrid
                                              </p>
                                              <p class="mb-0 pr-2">
                                                MAD
                                              </p>
                                              <p class="mb-0 pr-2">
                                                →
                                              </p>
                                              <p class="mb-0 pr-2">
                                                Guatemala
                                              </p>
                                              <p class="mb-0">
                                                GUA
                                              </p>
                                            </v-row>
                                            <v-row class="pa-2 pt-0">
                                              <p class="mb-0 pr-2 text-subtitle-2 font-weight-bold">
                                                Premiun Business
                                              </p>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </span>
                              </v-tooltip>
                            </p>
                            <!-- Finp para V tooltip -->
                            <a href="#">
                              <p class="p14Mine mb-0 d-flex justify-end align-center">
                                Reglas de la tarifa
                              </p>
                            </a>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4" class="pt-0">
                  <v-btn color="primary" width="100%" class="mb-2">
                    Enviar
                  </v-btn>
                  <v-btn color="#e3e1e4" width="100%">
                    Otras tarifas con equipaje<v-icon>{{ icons.mdiChevronDown }} </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiCar,
  mdiBed,
  mdiWalletTravel,
  mdiSeatPassenger,
  mdiAirplaneTakeoff,
  mdiAirplaneLanding,
  mdiAirplane,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiBagCarryOn,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiChevronDown,
  mdiInformation,
  mdiHeart,
  mdiHeartBroken,
  mdiHeartOutline,
  mdiClose,
  mdiArrowRight,
} from '@mdi/js'

export default {
  setup() {
    const tab = ref('')
    const countryList = ['USA', 'Canada', 'UK', 'Denmark', 'Germany', 'Iceland', 'Israel', 'Mexico']
    const languageList = ['English', 'German', 'French', 'Spanish', 'Portuguese', 'Russian', 'Korean']
    const hora = ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00']
    const transmision = ['Automatico', 'manual']
    const adicionales = ['GPS', 'Asiento para ninnos', 'Neumaticos para nieve']
    const clase = ['Economica', 'Premium', 'Business', 'Primera']
    const aerolinea = ['Todas las aerolineas', '1', '2', '3']

    return {
      tab,
      hora,
      countryList,
      languageList,
      transmision,
      adicionales,
      radioGroup: 1,
      clase,
      aerolinea,
      mostrarida: true,
      mostrarmultiple: false,
      mostraridavuelta: false,
      ubicacionDesde: null,
      ubicacionHasta: null,
      fechaIda: null,
      fechaVuelta: null,
      claseVuelo: null,
      adultos: 0,
      ninnos: 0,
      bebes: 0,
      aerolin: null,
      value: null,
      ubicacion: null,
      correo: null,
      nombre: null,
      auto: null,
      telef: null,
      checkbox: null,
      checkbox1: true,
      checkbox2: true,
      checkbox3: true,
      checkbox4: true,
      checkbox5: true,
      checkbox6: true,
      checkbox7: true,
      age: 6,
      dialogm1: '',
      dialog: false,
      dialog2: false,

      // icons
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiBagCarryOn,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiChevronDown,
        mdiInformation,
        mdiHeart,
        mdiHeartBroken,
        mdiHeartOutline,
        mdiClose,
        mdiArrowRight,
      },
    }
  },
  methods: {
    cambiaraIda() {
      this.mostrarida = !this.mostrarida
    },
    cambiaraIdaVuelta() {
      this.mostraridavuelta = !this.mostraridavuelta
    },
    cambiaraMultiple() {
      this.mostrarmultiple = !this.mostrarmultiple
    },
    MostrarIda() {
      if (this.mostraridavuelta === true) {
        this.cambiaraIdaVuelta()
      }
      if (this.mostrarmultiple === true) {
        this.cambiaraMultiple()
      }
      if (this.mostrarida === false) {
        this.cambiaraIda()
      }
    },
    MostrarIdaVuelta() {
      if (this.mostrarida === true) {
        this.cambiaraIda()
      }
      if (this.mostrarmultiple === true) {
        this.cambiaraMultiple()
      }
      if (this.mostraridavuelta === false) {
        this.cambiaraIdaVuelta()
      }
    },
    MostrarMultiple() {
      if (this.mostrarida === true) {
        this.cambiaraIda()
      }
      if (this.mostraridavuelta === true) {
        this.cambiaraIdaVuelta()
      }
      if (this.mostrarmultiple === false) {
        this.cambiaraMultiple()
      }
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine>div>p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine>div>h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>
