<template>
  <v-row class="match-height">
    <!-- form with tabs -->
    <v-col cols="12">
      <!-- <app-card-code title="Form with Tabs" :code="codeSnippets.formLayoutTabs"> -->
      <demo-form-layout-tabs></demo-form-layout-tabs>
      <!-- </app-card-code> -->
    </v-col>
  </v-row>
</template>

<script>
import DemoFormLayoutTabs from './demos/DemoFormLayoutTabs.vue'

export default {
  components: {
    DemoFormLayoutTabs,
  },
}
</script>
